import styled from "styled-components";
import { H4, ImageWrapper as _ImageWrapper, P } from "@/lib/styles";
import { smallLabelTypographicStyle } from "@/lib/styles/helpers";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: row;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
    a {
      color: ${({ theme }) => theme.colors.darkMiddle};
    }
  }
`;

export const ImageContainer = styled.div`
  flex: 1 0 40%;
  position: relative;
`;

export const TextContainer = styled.div`
  margin-top: 1.5rem;
  ${({ theme }) => theme.mediaQueries.laptop} {
    margin: 0 1.5rem;
  }
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Time = styled.time`
  display: block;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  font-weight: 400;
  color: rgba(50, 50, 50, 0.64);
  ${smallLabelTypographicStyle}
`;

export const HeadingWrapper = styled(H4)`
  display: block;
  margin-bottom: 1.5rem;
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  font-weight: 400;
  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 1rem;
  }
`;

export const Excerpt = styled(P)`
  font-family: ${({ theme }) => theme.fontFamilies.primary};
  color: rgba(50, 50, 50, 0.64);
`;

export const Heading = styled.div`
`;

export const Category = styled.span`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamilies.secondary};
  ${smallLabelTypographicStyle}
  font-weight: 700;
  margin-right: 1.5rem;
`;

export const CategoryTimeWrapper = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  ${({ theme }) => theme.mediaQueries.laptop} {
    margin-bottom: 2rem;
  }
`;

